<template>
  <div class="full-height-container-medium banner">
    <v-image class="image" :image="banner.image"/>
    <div class="content">
      <div class="grid-x">
        <div class="cell small-8 medium-6">
          <p class="title" v-html="banner.title"/>
          <p class="subtitle" v-html="banner.subtitle"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  export default {
    props: {
      banner: Object
    },
    components: {
      VImage
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .banner {
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .content {
    color: #fff;
    width: 100%;
    padding: vw-relative(80 20 20, 320);
    @include breakpoint(medium) {
      padding: vw-relative(38) vw-relative(72);
    }
  }
  .title {
    font: 300 #{vw-relative(20, 320)}/1.1 Brown;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-weight: 100;
      font-size: vw-relative(60);
    }
  }
  .subtitle {
    font: 300 #{vw-relative(14, 320)}/1.1 Brown;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(23);
    }
  }
</style>
